<template>
    <div class="echarts-container">
        <div class="echarts" ref="echarts"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    data() {
        return {};
    },
    mounted() {
        this.initCharts();
    },
    methods: {
        initCharts() {
            const option = {
                tooltip: {
                    trigger: "axis",
                    showContent: false,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                    },
                },
                // ['#9DBCFB', '#9CE8CA', '#F1A492', '#FAD773', '#F6BD16', '#E8684A']
                legend: {
                    data: [
                        {
                            name: "Email",
                            icon: "rect",
                            itemStyle: {
                                color: "#9DBCFB",
                            },
                        },
                        {
                            name: "Union Ads",
                            icon: "rect",
                            itemStyle: {
                                color: "#9CE8CA",
                            },
                        },
                        {
                            name: "Video Ads",
                            icon: "rect",
                            itemStyle: {
                                color: "#F1A492",
                            },
                        },
                        {
                            name: "Direct",
                            icon: "rect",
                            itemStyle: {
                                color: "#FAD773",
                            },
                        },
                    ],
                    textStyle: {
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular, PingFang SC",
                        fontSize: 12,
                        lineHeight: 12,
                        color: "#8C8C8C",
                    },
                    itemGap: 2,
                    itemHeight: 5,
                    itemWidth: 15,
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {},
                    },
                },
                grid: {
                    show: false,
                    top: "50",
                    left: "15",
                    bottom: "0",
                    right: "26",
                    containLabel: true,
                    borderWidth: "1",
                    borderColor: "rgba(0, 0, 0, 0.15)",
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    offset: 0,
                    data: ["1", "2", "3", "4", "5", "6", "7"],
                    axisLabel: {
                        color: "rgba(0, 0, 0, 0.45)",
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular, PingFang SC",
                        fontSize: 12,
                        lineHeight: 12,
                        nameGap: 3,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(0, 0, 0, 0.45)",
                        },
                    },
                    axisTick: {
                        length: 2,
                        alignWithLabel: true,
                        lineStyle: {
                            color: "rgba(0, 0, 0, 0.45)",
                        },
                    },
                    boundaryGap: [2, 2],
                },
                yAxis: {
                    max: 100,
                    type: "value",
                    nameTextStyle: {
                        color: "rgba(0, 0, 0, 0.45)",
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular, PingFang SC",
                        fontSize: 12,
                        lineHeight: 12,
                        nameGap: 3,
                    },
                    axisLabel: {
                        color: "rgba(0, 0, 0, 0.45)",
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular, PingFang SC",
                        fontSize: 12,
                        lineHeight: 12,
                        nameGap: 3,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(0, 0, 0, 0.08)",
                        },
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitNumber: 5,
                },
                axisPointer: {
                    lineStyle: {
                        type: "solid",
                        color: "rgba(171, 171, 171, 0.5)",
                    },
                },
                series: [
                    {
                        name: "Email",
                        type: "line",
                        stack: "Total",
                        symbol: "none",
                        itemStyle: {
                            color: "red",
                        },
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [120, 132, 101, 134, 90, 230, 210],
                        lineStyle: {
                            normal: {
                                // color: "#F6BD16",
                                width: 1,
                            },
                        },
                        triggerLineEvent: true,
                    },
                    {
                        name: "Union Ads",
                        type: "line",
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [220, 182, 191, 234, 290, 330, 310],
                        lineStyle: {
                            normal: {
                                // color: "#F6BD16",
                                width: 1,
                            },
                        },
                    },
                    {
                        name: "Video Ads",
                        type: "line",
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [150, 232, 201, 154, 190, 330, 410],
                        lineStyle: {
                            normal: {
                                // color: "#F6BD16",
                                width: 1,
                            },
                        },
                    },
                    {
                        name: "Direct",
                        type: "line",
                        stack: "Total",
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [320, 332, 301, 334, 390, 330, 320],
                        lineStyle: {
                            normal: {
                                // color: "#F6BD16",
                                width: 1,
                            },
                        },
                    },
                    {
                        name: "Search Engine",
                        type: "line",
                        stack: "Total",
                        symbol: "none",
                        label: {
                            show: true,
                            position: "top",
                        },
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        lineStyle: {
                            normal: {
                                // color: "#F6BD16",
                                width: 1,
                            },
                        },
                    },
                ],
            };
            const myChart = echarts.init(this.$refs.echarts, null, {
                width: "auto",
                height: "200",
                useDirtyRect: true,
                renderer: "svg",
            });
            myChart.setOption(option);
        },
    },
};
</script>

<style lang="scss" scoped>
.echarts-container {
    .echarts {
        width: 100%;
        height: 800px;
    }
}
</style>
